import React from 'react'

const Footer = () => (
  <footer className="section">
    <div className="container">
      <hr/>
      <div className="columns">
      <div className="column is-6">
        <div className="has-text-centered">
          <p>&copy; 2018 Barker Flood Prevention Advocacy Group<br />
          Website provided by <a href="//touchstonedistrictservices.com/">Touchstone</a>
          </p>
        </div>
      </div>
      <div className="column is-6 has-text-centered">
        <p className="is-size-5">Connect with Us</p>
        <p>Facebook: <a href="https://www.facebook.com/barkerfloodprevention" target="_blank" rel="noopener noreferrer">@barkerfloodprevention</a><br/>
        Email: <a href="mailto:brfloodprevention@gmail.com">brfloodprevention@gmail.com</a></p>
      </div>
      </div>
    </div>
  </footer>
)

export default Footer;