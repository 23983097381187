import React from 'react'
import Helmet from 'react-helmet'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import "typeface-open-sans"
import './all.sass'

const TemplateWrapper = ({ children }) => (
  < >
    <Helmet title="Barker Flood Prevention Advocacy Group" />
    <Navbar />
    {children}
    <Footer />
  </ >
)

export default TemplateWrapper
