import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Logo from '../img/logo-rem.svg'

class Navbar extends React.Component {
  render() {
    function toggleMenu() {
      document.querySelector('.navbar-burger').classList.toggle('is-active');
      document.querySelector('.navbar-menu').classList.toggle('is-active');
      console.log('menu pressed');
    }
    return (
      <nav className="navbar">
        <div className="container" style={{ padding: '1rem', alignItems: 'flex-end', minHeight: '110px' }}>
              <div className="navbar-brand">
                <Link to="/" className="">
                  <figure className="image">
                    <img src={Logo} style={{width: '475px'}} alt="logo" />
                  </figure>
                </Link>
                <a className="navbar-burger" onClick={toggleMenu} role="button" aria-label="menu" aria-expanded="false">
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>
              <div className="navbar-menu" style={{ marginBottom: '0.5rem'}}>
                <div className="navbar-end">
                <Link className="navbar-item" to="/problem">Problem</Link>
                <Link className="navbar-item" to="/solutions">Solutions</Link>
                <Link className="navbar-item" to="/past-studies">Past Studies</Link>
                  <div className="navbar-item has-dropdown is-hoverable">
                    <div className="navbar-link">More Info</div>
                    <div className="navbar-dropdown">
                      <Link className="navbar-item" to="/the-advocate-newsletter">The Advocate Newsletter</Link>
                      <Link className="navbar-item" to="/meetings">Meetings We've Attended</Link>
                      <Link className="navbar-item" to="/co-founders">Co-Founders</Link>
                      <Link className="navbar-item" to="/legislation">Legislation</Link>
                      <Link className="navbar-item" to="/in-the-works">In the Works</Link>
                      <Link className="navbar-item" to="/calendar">Calendar</Link>
                      <Link className="navbar-item" to="/map">Map</Link>
                    </div>
                  </div>
                <Link className="navbar-item" to="/contact-your-officials">Contact Your Elected Officials</Link>
                {/*<Link className="navbar-item button is-primary" to="/">Join our advocacy group</Link>*/}
                </div>
              </div>
          </div>
      </nav>
    )
  }
} 

export default Navbar
